<template>
  <header class="top-header">
    <b-container class="container-header rounded">
      <b-row>
        <b-col class="d-none d-md-block" md="3">
          <img src="@/assets/images/logo_digipres.png" alt="dpresfi"/>
          <a class="header-link"
             :href="url.digitalpreservationfi" target="_blank">
            Digital preservation
          </a>
        </b-col>
        <b-col md="9" lg="7" />
        <b-col lg="2" align-self="center" style="margin-bottom: -10px">
          <b-navbar-nav class="ml-auto">
            <b-nav-item>
              <font-awesome-icon class="fa-1x header-lang"
                                 :icon="['fas', 'globe']" />
              <a @click="changeLang('fi')" @keyup.enter="changeLang('fi')" class="header-lang" tabindex="0">
                <translate translate-context="Header">fi</translate>
              </a>
              |
              <a @click="changeLang('en')" @keyup.enter="changeLang('en')" class="header-lang" tabindex="0">
                <translate translate-context="Header">en</translate>
              </a>
            </b-nav-item>
          </b-navbar-nav>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
export default {
  name: 'TopHeader',
  methods: {
    changeLang (lang) {
      this.$language.current = lang
      this.$router.push({
        name: this.$route.name,
        params: {
          id: this.$route.params.id,
          lang: lang
        }
      })
        .catch(err => {
          // Ignore navigation duplicated error
          if (err.name !== 'NavigationDuplicated') {
            console.log(err)
          }
        })
    }
  },
  computed: {
    url () {
      if (this.$language.current === 'en') {
        return {
          digitalpreservationfi: 'https://digitalpreservation.fi/en'
        }
      }
      return {
        digitalpreservationfi: 'https://digitalpreservation.fi/'
      }
    }
  }
}
</script>
