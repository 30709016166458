<template>
  <footer class="main-footer">
    <b-container class="container-footer rounded">
      <b-row align-v="center">
        <b-col class="col-lg-4">
            <h5>
            <translate translate-context="Footer dpres">
              footer_dpres_title
            </translate>
            </h5>
            <p class="footer-text">
            <translate translate-context="Footer dpres">
              footer_dpres_csc
            </translate>
            </p>
            <p>
            <a class="footer-mailto"
                 href="mailto:pas-support@csc.fi">pas-support@csc.fi</a>
            </p>
        </b-col>
        <b-col class="col-lg-4 align-self=middle text-right">
            <p></p>
            <p>
              <a class="footer-link"
                 :href="url.digitalpreservationfi" target="_blank">digitalpreservation.fi</a>
            </p>
            <p>
              <a class="footer-link"
                 href="https://github.com/Digital-Preservation-Finland" target="_blank">GitHub</a>
            </p>
            <p>
              <a class="footer-link"
                 href="https://twitter.com/dpres_FI" target="_blank">
                <translate translate-context="Footer dpres">
                  footer_dpres_x
                </translate>
              </a>
            </p>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    url () {
      if (this.$language.current === 'en') {
        return {
          digitalpreservationfi: 'https://digitalpreservation.fi/en'
        }
      }
      return {
        digitalpreservationfi: 'https://digitalpreservation.fi/'
      }
    }
  }
}
</script>
