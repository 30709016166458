import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const VALID_LANGUAGES = ['fi', 'en']

const routes = [
  {
    path: '/:lang?/:id?',
    name: 'Validate',
    component: () =>
      import(/* webpackChunkName: "validate" */ '@/views/Validate.vue'),
    beforeEnter: (to, from, next) => {
      if (typeof to.params.lang === 'undefined' || VALID_LANGUAGES.includes(to.params.lang)) {
        next()
      } else {
        next({ name: 'Validate', params: { lang: 'fi', id: to.params.id } })
      }
    }
  },
  {
    // path: "*",
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
