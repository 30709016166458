<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import Main from '@/components/Main.vue'
import TopHeader from '@/components/TopHeader.vue'

export default {
  name: 'App',
  components: {
    Footer,
    TopHeader,
    Main
  },
  computed: {
    isAdminView () {
      return this.$route ? this.$route.meta.viewType === 'admin' : false
    }
  }
}
</script>
<template>
  <div id="app">
    <b-container fluid>
      <b-row>
        <b-col cols="12" class="main">
          <TopHeader />
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col cols="12" class="main">
          <Main />
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col cols="12" class="main">
          <Footer />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
